<template>
  <ProfileButton />
  <Loading v-if="isLoading" />
  <button class="btn btn-link back-button" @click="redirectToAccounts">
    <img src="@/assets/images/arrow-left-silver.png" alt="Voltar" />
  </button>
  <div class="d-flex flex-column align-items-center justify-content-top py-5 h-100">
    <router-link :to="{ name: 'accounts-show', params: { id: account.id }, query: { index: 0 } }">
      <img :src="account.picture" alt="Foto de perfil" class="picture-profile circle-green config-profile-picture" />
    </router-link>
    <span class="fw-bold fs-15 mt-2">
      @{{ account.name }}
    </span>
    <span class="fw-bold fs-20 mt-4">Configurações</span>
    <div class="d-flex flex-column mt-3 width-config">
      <span class="fc-label fw-bold fs-12">Visualização</span>
      <div class="d-flex justify-content-between">
        <div>
          <input type="radio" id="stories" name="radio-group-view" value="stories" v-model="account.view">
          <label for="stories" class="fs-12 fc-label">Stories</label>
        </div>
        <div>
          <input type="radio" id="reels" name="radio-group-view" value="reels" v-model="account.view">
          <label for="reels" class="fs-12 fc-label">Reels</label>
        </div>
        <div>
          <input type="radio" id="storiesreels" name="radio-group-view" value="stories_and_reels" v-model="account.view">
          <label for="storiesreels" class="fs-12 fc-label">Stories/Reels</label>
        </div>
      </div>

      <hr />

      <span class="fc-label fw-bold fs-15" v-if="hasStories">Stories</span>
      <span class="fc-label fw-bold fs-12 mt-3" v-if="hasStories">Ver stories das últimas</span>
      <div class="d-flex justify-content-between mt-1" v-if="hasStories">
        <div>
          <input type="radio" id="3hours" name="radio-group-story_hour_limit" :value="3" v-model="account.story_hour_limit">
          <label for="3hours" class="fs-12 fc-label">3 horas</label>
        </div>
        <div>
          <input type="radio" id="6hours" name="radio-group-story_hour_limit" :value="6" v-model="account.story_hour_limit">
          <label for="6hours" class="fs-12 fc-label">6 horas</label>
        </div>
        <div>
          <input type="radio" id="12hours" name="radio-group-story_hour_limit" :value="12" v-model="account.story_hour_limit">
          <label for="12hours" class="fs-12 fc-label">12 horas</label>
        </div>
        <div>
          <input type="radio" id="24hours" name="radio-group-story_hour_limit" :value="24" v-model="account.story_hour_limit">
          <label for="24hours" class="fs-12 fc-label">24 horas</label>
        </div>
      </div>

      <span class="fc-label fw-bold fs-12 mt-3" v-if="hasStories">Tempo de exibição (fotos)</span>
      <div class="d-flex justify-content-between mt-1" v-if="hasStories">
        <div>
          <input type="radio" id="5seconds" name="radio-group-seconds" :value="5" v-model="account.duration">
          <label for="5seconds" class="fs-12 fc-label">5 segundos</label>
        </div>
        <div class="mx-4">
          <input type="radio" id="10seconds" name="radio-group-seconds" :value="10" v-model="account.duration">
          <label for="10seconds" class="fs-12 fc-label">10 segundos</label>
        </div>
        <div>
          <input type="radio" id="15seconds" name="radio-group-seconds" :value="15" v-model="account.duration">
          <label for="15seconds" class="fs-12 fc-label">15 segundos</label>
        </div>
      </div>

      <hr v-if="hasStories" />

      <span class="fc-label fw-bold fs-15" v-if="hasReels">Reels</span>
      <span class="fc-label fw-bold fs-12 mt-3" v-if="hasReels">Selecionar reels</span>
      <div class="d-flex flex-start flex-wrap" v-if="hasReels">
        <div class="width-item-reel-config">
          <input type="radio" id="last5" name="radio-group-reels-count-limit" :value="5" v-model="account.reels_count_limit">
          <label for="last5" class="fs-12 fc-label">5 últimos</label>
        </div>
        <div class="width-item-reel-config">
          <input type="radio" id="last10" name="radio-group-reels-count-limit" :value="10" v-model="account.reels_count_limit">
          <label for="last10" class="fs-12 fc-label">10 últimos</label>
        </div>
        <div class="width-item-reel-config">
          <input type="radio" id="hashtag" name="radio-group-reels-count-limit" :value="-1" v-model="account.reels_count_limit">
          <label for="hashtag" class="fs-12 fc-label">Com #meustorytv</label>
        </div>
      </div>

      <hr v-if="hasReels" />

      <!-- <div>
        <input type="checkbox" id="random" v-model="account.random" />
        <label for="random" class="fc-label fs-12">Modo aleatório</label>
      </div>

      <hr /> -->

      <span class="fc-label fw-bold fs-12">Tela</span>
      <div class="d-flex flex-wrap">
        <div class="me-4">
          <input type="radio" id="horizontal" name="radio-group-degrees" :value="0" v-model="account.degrees" @change="showQuantityOptions">
          <label for="horizontal" class="fs-12 fc-label">Horizontal</label>
        </div>
        <div class="me-4">
          <input type="radio" id="rotate_90" name="radio-group-degrees value=" :value="90" v-model="account.degrees" @change="showQuantityOptions">
          <label for="rotate_90" class="fs-12 fc-label">Vertical (girar 90º para direita)</label>
        </div>
        <div class="me-4">
          <input type="radio" id="rotate_270" name="radio-group-degrees value=" :value="270" v-model="account.degrees" @change="showQuantityOptions">
          <label for="rotate_270" class="fs-12 fc-label">Vertical (girar 90º para esquerda)</label>
        </div>
      </div>

      <hr />

      <span class="fc-label fw-bold fs-12">Quantidade de exibições por tela</span>
      <div class="d-flex mb-5">
        <div>
          <input type="radio" id="onlyone" name="radio-group-quantity" :value="1" v-model="account.quantity_show">
          <label for="onlyone" class="fs-12 fc-label">1</label>
        </div>
        <div class="mx-4" v-if="account.degrees === 0">
          <input type="radio" id="three" name="radio-group-quantity" :value="3" v-model="account.quantity_show">
          <label for="three" class="fs-12 fc-label">3</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import ProfileButton from '@/components/ProfileButton.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'AccountConfig',
  data() {
    return {
      account: {},
      isLoading: true,
    }
  },
  components: {
    ProfileButton,
    Loading
  },
  created() {
    this.fetchAccount(this.$route.params.id)
  },
  computed: {
    hasStories() {
      return this.account.view === 'stories' || this.account.view === 'stories_and_reels'
    },
    hasReels() {
      return this.account.view === 'reels' || this.account.view === 'stories_and_reels'
    }
  },
  methods: {
    fetchAccount(id) {
      api.accounts.getById(id)
        .then(response => {
          this.account = response.data
        })
        .catch(error => {
          console.error(error)
        }).finally(() => {
          this.isLoading = false
        })
    },
    redirectToAccounts() {
      this.$router.push('/accounts')
    },
    updateAccount(account) {
      api.accounts.update(account)
        .catch(error => {
          console.error(error)
        })
    },
    showQuantityOptions() {
      if (this.account.degrees > 0) {
        this.account.quantity_show = 1
      }
    }
  },
  watch: {
    account: {
      handler(account) {
        this.updateAccount(account)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.back-button {
  position: absolute;
  top: 30px;
  left: 30px;
}

.picture-profile {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.width-config {
  min-width: 400px;
  max-width: 400px;
}

.width-item-reel-config {
  width: 130px;
}
</style>
