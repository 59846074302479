const locale = {
  no_medias: {
    message: 'Nenhum story/reels encontrados nesta conta'
  },
  error_plan_expired: {
    buttonConfirm: 'Conversar com o time',
    message: 'Seu plano expirou, por favor, entre em contato com o suporte',
    confirm: () => {
      window.open('https://wa.me/5551999153493?text=Quero%20come%C3%A7ar%20usar%20o%20MeuStory.TV', '_blank')
    }
  },
  error_fetch_medias: 'Erro ao buscar stories/reels, por favor, tente novamente',
  understood: 'Entendi'
}

export default locale