<template>
  <ProfileButton />
  <Loading v-if="loading" />
  <AccountCard
    v-else-if="accounts"
    :accounts="accounts"
    :current-account="current_account"
    @delete-account="deleteAccount"
    @create-account="createAccount"
  />
  <AccountCard v-else />
  <MessageBox
    :visible="messageBox.visible"
    @close="messageBox.close"
    @confirm="messageBox.confirm"
    :message="messageBox.message"
    :buttonConfirmText="messageBox.buttonConfirm"
    :buttonCloseText="messageBox.buttonClose"
  />
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import AccountCard from './components/AccountCard.vue'
import ProfileButton from '@/components/ProfileButton.vue'
import Loading from '@/components/Loading.vue'
import { deleteCookie, clearAllIntervals } from '@/utils/cookies'
import MessageBox from '@/components/MessageBox.vue'
import locale from '@/config/locales'

export default {
  name: 'AccountsView',
  components: {
    AccountCard,
    ProfileButton,
    Loading,
    MessageBox
  },
  data() {
    return {
      accounts: [],
      current_account: 0,
      loading: true,
      messageBox: {
        visible: false,
        message: ''
      }
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    }

    deleteCookie('authorization_code')
    clearAllIntervals()

    if (this.$route.query.authorization_code) {
      api.auth.setVuex({
        authorization_code: this.$route.query.authorization_code,
        token: localStorage.getItem('jwt-token'),
        vuex: localStorage.getItem('vuex')
      })
    }

    if (this.$route.query.code) {
      this.addAccounts(
        this.$route.query.code,
        this.$route.query.state
      )
    } else {
      this.fetchAccounts()
    }

    const closeMessage = () => { this.messageBox.visible = false }

    if (this.$route.query.message) {
      this.messageBox.type = this.$route.query.message
      this.messageBox.buttonConfirm = locale[this.$route.query.message].buttonConfirm || locale.understood
      this.messageBox.visible = true
      this.messageBox.message = locale[this.$route.query.message].message
      this.messageBox.confirm = () => {
        const functionConfirm = locale[this.messageBox.type].confirm
        if (functionConfirm) functionConfirm()

        closeMessage()
      }

      this.messageBox.close = () => {
        this.messageBox.visible = false
      }

      this.$router.replace({ query: {} })
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    fetchAccounts() {
      this.loading = true
      api.accounts.get()
        .then(response => {
          this.accounts = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteAccount(id) {
      this.loading = true
      api.accounts.delete(id)
        .then(() => {
          this.fetchAccounts()
        })
        .catch(() => {
          this.loading = false
        })
    },
    createAccount(name) {
      this.loading = true
      api.accounts.create({ name })
        .then(() => {
          this.fetchAccounts()
        })
        .catch(() => {
          this.loading = false
        })
    },
    addAccounts(code, state) {
      api.accounts.callback({ code, state })
        .then(() => {
          this.fetchAccounts()
        })
    }
  }
}
</script>